import { authRoles } from "src/app/roles";
import SGDT from "./SGDT";
import Config from "./Config";
import Logs from "./Logs";

const sgdtRoutes = [
    {
        path: "cartelle/sgdt",
        element: <SGDT />,
    },
    {
        path: "config/sgdt",
        element: <Config />,
        auth: authRoles.editor
    },
    {
        path: "config/sgdt/logs",
        element: <Logs />,
        auth: authRoles.editor
    },
];

export default sgdtRoutes;
