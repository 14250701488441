import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Modal, Stack, Table } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { useFormBuilderSchemaLoader, useSelectItemsPopulate } from 'src/app/helpers/data';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { ButtonBar } from './Recapiti';
import { useChangeComuniByProvincia, _assoc } from '../common';

interface Props {
	single?: boolean;
	items: any[];
	related: any;
    onChange: (values: any) => void;
}

const Domicili = (props: Props) => {
    const { single, items, related, onChange } = props;
	const [record, setRecord] = useState<any>();

	const handleClose = () => setRecord(undefined);

	const province = _get(related.data, "elenchiQuery.elenchiGeneraliQuery.provincie.list", []);
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-domicilio');
	schema = useSelectItemsPopulate(
		schema as Schema,
		related, {
			'luogo.nazioneUniqueId': _get(related.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).map((cat: any) => ({ text: cat.denominazioneIT, value: cat.uniqueId })),
			'luogo.provinciaUniqueId': province.map(_assoc),
		}
	);
	const { onChangeProvincia } = useChangeComuniByProvincia();

	const onRemove = useCallback(async (index) => {
        onChange(items.filter((item, i) => i !== index));
	}, [items]);

	const onFormChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;
		const flag = _get(values, `coincideConResidenza`);
		['denominazione', 'note', 'luogo.localita', 'luogo.comuneEstero', 'luogo.nazioneUniqueId', 'luogo.provinciaUniqueId', 'luogo.comuneUniqueId', 'luogo.cap'].forEach(k => {
			const name = `${k}`;
			const node = getSchemaNode(name);
			if (node) {
				setSchemaNode(name, {
					...node,
					props: {
						...(node.props || {}),
						disabled: flag,
					},
					hidden: flag,
				});
			}
		});
		onChangeProvincia({ ns: 'luogo', ...args });
	};

	const Form = ({ initialValues }) => {
		return <FormViewer
			schema={schema as any}
			initialValues={initialValues}
			onInit={(form: any) => {
				if (onFormChange) {
					onFormChange({
						...form,
					});
				}
			}}
			onChange={(form: any) => {
				if (onFormChange) {
					onFormChange({
						...form,
					});
				}
			}}
			onSubmit={(args: any) => {
				const { values } = args;
				const provincia = province.find(t => t.uniqueId === _get(values, 'luogo.provinciaUniqueId'));
				const obj = { ...values, provincia };
				const newValues = obj.index === undefined ? [...items, obj] : items.map((item, i) => i === obj.index ? obj : item);
				onChange(newValues);
				handleClose();
			}}
			slots={{
				ButtonBar: (args) => <ButtonBar args={args} onCancel={handleClose} />
			}}
		/>
	}

	if (related.loading) return null;
	/*if (single) {
		return <Form initialValues={items && items.length > 0 ? items[0] : {}} />
	}*/
	return <Fragment>
		<Card className='my-3'>
      		<Card.Body>
				<Stack direction="horizontal" gap={3}>
        			<Card.Title>Domicili</Card.Title>
					{(!single || items.length === 0) && <Button variant='success' className='ms-auto' onClick={() => setRecord({})}>Aggiungi</Button>}
				</Stack>
				<Card.Text>
					{items.length === 0 && <div className='empty'>Nessun domicilio inserito</div>}
					{items.length > 0 && <Table striped bordered hover>
						<thead>
							<tr>
								<th>Denominazione</th>
								<th>Provincia</th>
								<th>Comune</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{items.map((row, i) => {
							return <tr key={i} style={{ cursor: 'pointer' }} onClick={() => setRecord({ index: i, ...row })}>
								{row.coincideConResidenza ? <>
									<td colSpan={3}>Coincide con residenza</td>
								</> : <>
									<td>{row.denominazione}</td>
									<td>{_get(row, 'luogo.provincia.descrizione')}</td>
									<td>{_get(row, 'luogo.comune.descrizione')}</td>
								</>}
								<td>
									<Button onClick={(e) => {
										e.stopPropagation();
										if (window.confirm("Vuoi continuare?")) {
											onRemove(i);
										}
									}}>Elimina</Button>
								</td>
							</tr>;
						})}
						</tbody>
					</Table>}
				</Card.Text>
			</Card.Body>
   		</Card>
		<Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<Form initialValues={record || {}} />
			</Modal.Body>
		</Modal>
	</Fragment>
}

export default Domicili;