import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gql, useDataRequest } from '@ai4/data-request';
import RecordsManagement, { Formatters, usePaginationArgs } from '@ai4/records-management';
import { setMessage } from 'src/@bootstrap/features/app';
import { formatDate } from 'src/app/helpers/helpers';
import { boolFormatter } from 'src/app/helpers/formatters';
import LogView from './edit/LogView';

const SGDT_LOG_IPP = 10;

const Logs = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
	const { onPageChange, onSizePerPageChange, pageNumber, pageSize, } = usePaginationArgs();
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/sgdt/logs',
        method: 'GET',
        jwt: true,
    });

    useEffect(() => {
        load();
    }, [pageNumber, pageSize]);

    const load = useCallback(async () => {
        setLoading(true);
        try {
            const res = await reqList({
                queryString: {
                    currentPage: `${pageNumber || 1}`,
                    pageSize: `${pageSize || SGDT_LOG_IPP}`,
                },
            });
            setLoading(false);
        }
        catch (e) {
            dispatch(setMessage({ type: 'error', text: 'Errore durante la lettura dei logs' }));
            setLoading(false);
        }
    }, [pageNumber, pageSize]);

    
	const rows = _get(resList, 'data.data', []);
    const paginationExt = useMemo(() => {
		const { data: _, ...rest } = resList.data || {};
		return {
			...rest,
			onPageChange,
			onSizePerPageChange,
		};
	}, [resList]);

	const args = {
		module,
		entity: 'sgdt-logs',
		columns: [
			{
				dataField: 'timestamp',
				text: 'Data',
				formatter: (cell) => formatDate(cell, 'full')
			},
			{
				dataField: 'level',
				text: 'Livello',
			},
			{
				dataField: 'message',
				text: 'Messaggio',
			},
			{
				dataField: 'operation',
				text: 'Operazione',
			},
			{
				dataField: 'direzione',
				text: 'direzione',
                // formatter: (cell: any) => (cell ? 'positivo' : 'negativo'),
			},
            {
                dataField: "haveContent",
                text: "Contenuto",
                formatter: boolFormatter
            },
		],
		rows,
		dataLoading: resList.loading,
        paginationExt,
		formSchema: {},
		submitResponse: null,
		hideEditColumns: true,
        clickRowToSelect: true,
		slots: {
			header: ({ onCreate }) => {
				return <div>
					<h2>{`SGDT Logs`}</h2>
				</div>
			},
            edit: LogView,
		},
		onBoot: () => {},
		onInit: () => {},
		onChange: () => {},
		onSubmit: () => {},
		onCancel: () => {},
		// onSelectedRow: () => {},
		onDeleteRow: () => {},
		onAddExtraData: () => {},
	}
	return <>
		{/* @ts-ignore */}
		<RecordsManagement
			{...args}
			
		/>
	</>
}


export default Logs;