/***
 * @template
 * warning: this file exists in base repo, check for wrong overrides
 */

import React from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "src/@bootstrap/auth/AuthGuard";
import sessionsRoutes from "src/@bootstrap/views/sessions/sessionsRoutes";
import toolsRoutes from "src/@bootstrap/views/tools/toolsRoutes";
// app
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import cartelleRoutes from "./views/cartelle/cartelleRoutes";
import anagraficheRoutes from "./views/anagrafiche/anagraficheRoutes";
import struttureServiziRoutes from "./views/strutture-servizi/struttureServiziRoutes";
import accessiRoutes from "./views/accessi/accessiRoutes";
import reportsRoutes from "src/app/views/reports/reportsRoutes";
import appConfigRoutes from "./views/config/configRoutes";
import sgdtRoutes from "./views/sgdt/sgdtRoutes";
// import formSamplesRoutes from "./views/form-samples/formSamplesRoutes";
// import progettiRoutes from "./views/progetti/progettiRoutes";
// template
import configRoutes from "src/@bootstrap/views/config/configRoutes";
import demoRoutes from "src/app/views/@demo/demoRoutes";
import accountRoutes from "src/@bootstrap/views/account/accountRoutes";
import usersRoutes from "src/app/views/@users/usersRoutes";
import tenantsRoutes from "src/app/views/@tenants/tenantsRoutes";
import { authRoles } from "src/app/roles";

const indexRoute = [
    {
        path: "",
        exact: true,
        element: <Navigate replace to="/dashboard/supervisor/DashboardSupervisor" />,
        auth: authRoles.guest
    }
];

const errorRoute = [
    {
        id: '404',
        path: "404",
        element: <Navigate to="/session/404" />
    }
];

const routes = [
    ...sessionsRoutes,
    {
        id: 'root',
        path: "/*",
        element: <AuthGuard />,
        children: [
            ...indexRoute,
            ...errorRoute,
            ...toolsRoutes,
            ...dashboardRoutes,
            ...cartelleRoutes,
            ...anagraficheRoutes,
            ...struttureServiziRoutes,
            ...accessiRoutes,
            ...reportsRoutes,
            ...configRoutes,
            ...appConfigRoutes,
            ...sgdtRoutes,
            ...accountRoutes,
            ...usersRoutes,
            ...tenantsRoutes,
            // to activate demo section uncomment following line
            ...demoRoutes,
        ]
    }
];

export default routes;
