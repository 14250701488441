import _get from 'lodash/get';
import _set from 'lodash/set';
import { useCallback, useEffect, useContext, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Formatters } from "@ai4/records-management";
import { gql, useDataRequest } from "@ai4/data-request";
import {
	useFormBuilderSchemaLoader,
	useHideNodesSchema,
	useKeepRequiredNodesSchema,
	useReadonlyNodesSchema,
	useSelectItemsPopulate,
} from "../../helpers/data";
import { pathToSlug } from 'src/app/helpers/helpers';
import { useAnagraficaModule, useAnagraficaSelectItemsPopulate, useChangeComuniByProvincia, useFillTabs, _assoc } from './common';
import EditAssistito from './editAssistito';
import { GET_ASSISTITO } from './gql/assistiti';
import { Button } from 'react-bootstrap';

const TIPI = gql`
	query getTipologieMedici {
		anagraficaQuery {
            elenchiAnagrafica {
              	tipologiaMedico {
					list {
						uniqueId
						codice
						descrizione
					}
				}
            }
        }
	}
`;

const MEDICI = gql`
	query getMedici($tipoMedicoDiFamiglia: Guid) {
		anagraficaQuery {
			medici {
				list(
					where: {
						tipologiaMedicoUniqueId: {
							_eq: $tipoMedicoDiFamiglia
						}
					}
				) {
					uniqueId
					datiAnagrafici {
						nome
						cognome
             			codiceFiscale
					}
				}
			}
		}
	}
`;

export const usePreModule = () => {
    const { useQuery } = useDataRequest();
    const related = useQuery(TIPI);
    const tipi = _get(related.data, 'anagraficaQuery.elenchiAnagrafica.tipologiaMedico.list');

    if (related.loading) return null;
    const tipoMedicoDiFamiglia = tipi.find((t: any) => t.codice.toUpperCase() === 'F');

    return {
        tipoMedicoDiFamiglia
    };
}

export const useModule = (options: any) => {
	const { tipoMedicoDiFamiglia } = options;
	const list = 'anagraficaQuery.assistiti';
	const $common = useAnagraficaModule({ 
		module: 'anagrafica',
		entity: 'assistiti', 
		graphql: { list: pathToSlug(list), details: GET_ASSISTITO },
		lists: ['titoloDiStudio', 'statusOccupazione', 'statoCondizioneLavorativa', 'statoServiziPerImpiego', 'statoContributoNASPI', 'statoPattoDiLavoro', 'statoDichiarazioneDisponibilita', 'classeInvalidita', 'certificazioneDisabilita', 'tipologiaDocumentoSoggiorno', 'tipologiaPermesso', 'motivoAccesso', 'tipologiaEnteEmittente', 'condizioneMinore', 'tutelaMinore', 'enteTerritorialePrimoLivello', 'enteTerritorialeSecondoLivello', 'ambitoTerritoriale', 'tipologiaScuolaFrequentata']
	});
	const navigate = useNavigate();
    const { useQuery, useLazyQuery } = useDataRequest();
    const mediciDiFamiglia = useQuery(MEDICI, { variables: { tipoMedicoDiFamiglia: tipoMedicoDiFamiglia ? tipoMedicoDiFamiglia.uniqueId : undefined }});
	const mutation = "anagrafica.assistiti";
	const query = useLazyQuery(GET_ASSISTITO);
	const [execDetails, { data: dataDetails, loading }] = query;
    
	// build schema	
	var schema = useFormBuilderSchemaLoader('presets.assistito');
	schema = useFillTabs(schema, { 'anagrafica': ['anagrafica', 'anagrafica-assistiti'], 'studiolavoro': ['studiolavoro-assistiti'], 'sanita': ['sanita'], 'contatti': ['contatti'], 'contratti': false });
	schema = useAnagraficaSelectItemsPopulate({ schema, related: $common.related });
	schema = useKeepRequiredNodesSchema(schema, ['datiAnagrafici.codiceFiscale']);
	schema = useSelectItemsPopulate(schema, mediciDiFamiglia, {
		'medicoDiFamigliaUniqueId': [
			..._get(mediciDiFamiglia.data, "anagraficaQuery.medici.list", []).map((cat: any) => ({ text: `${cat.datiAnagrafici.cognome} ${cat.datiAnagrafici.nome} (${cat.datiAnagrafici.codiceFiscale})`, value: cat.uniqueId })),
		],
	});
	schema = useSelectItemsPopulate(schema, $common.related, {
		'datiIstruzione.titoloDiStudioUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.titoloDiStudio.list", []).map(_assoc),
			{ listManagement: { name: 'TitoloDiStudio', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statusOccupazioneUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statusOccupazione.list", []).map(_assoc),
			{ listManagement: { name: 'StatusOccupazione', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statoCondizioneLavorativaUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statoCondizioneLavorativa.list", []).map(_assoc),
			{ listManagement: { name: 'StatoCondizioneLavorativa', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statoServiziPerImpiegoUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statoServiziPerImpiego.list", []).map(_assoc),
			{ listManagement: { name: 'StatoServiziPerImpiego', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statoContributoNASPIUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statoContributoNASPI.list", []).map(_assoc),
			{ listManagement: { name: 'StatoContributoNASPI', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statoPattoDiLavoroUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statoPattoDiLavoro.list", []).map(_assoc),
			{ listManagement: { name: 'StatoPattoDiLavoro', query_name: 'getRelatedAnagrafica' } },
		],
		'datiOccupazione.statoDichiarazioneDisponibilitaUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.statoDichiarazioneDisponibilita.list", []).map(_assoc),
			{ listManagement: { name: 'StatoDichiarazioneDisponibilita', query_name: 'getRelatedAnagrafica' } },
		],
		'datiSanitari.classeInvaliditaUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.classeInvalidita.list", []).map(_assoc),
			{ listManagement: { name: 'ClasseInvalidita', query_name: 'getRelatedAnagrafica' } },
		],
		'datiSanitari.certificazioneDisabilitaUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.certificazioneDisabilita.list", []).map(_assoc),
			{ listManagement: { name: 'CertificazioneDisabilita', query_name: 'getRelatedAnagrafica' } },
		],
		'datiStraniero.documentoSoggiorno.tipologiaDocumentoSoggiornoUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaDocumentoSoggiorno.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaDocumentoSoggiorno', query_name: 'getRelatedAnagrafica' } },
		],
		'datiStraniero.documentoSoggiorno.tipologiaPermessoUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaPermesso.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaPermesso', query_name: 'getRelatedAnagrafica' } },
		],
		'datiStraniero.documentoSoggiorno.motivoAccessoUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.motivoAccesso.list", []).map(_assoc),
			{ listManagement: { name: 'MotivoAccesso', query_name: 'getRelatedAnagrafica' } },
		],
		'datiStraniero.documentoSoggiorno.tipologiaEnteEmittenteUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaEnteEmittente.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaEnteEmittente', query_name: 'getRelatedAnagrafica' } },
		],
		'datiStraniero.documentoSoggiorno.nazioneProvenienzaUniqueId': [
			..._get($common.related.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).map((cat: any) => ({ text: cat.denominazioneIT, value: cat.uniqueId })),
		],
		'datiMinore.condizioneMinoreUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.condizioneMinore.list", []).map(_assoc),
			{ listManagement: { name: 'CondizioneMinore', query_name: 'getRelatedAnagrafica' } },
		],
		'datiMinore.tutelaMinoreUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.tutelaMinore.list", []).map(_assoc),
			{ listManagement: { name: 'TutelaMinore', query_name: 'getRelatedAnagrafica' } },
		],
		'datiTerritoriali.enteTerritorialePrimoLivelloUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.enteTerritorialePrimoLivello.list", []).map(_assoc),
			{ listManagement: { name: 'EnteTerritorialePrimoLivello', query_name: 'getRelatedAnagrafica' } },
		],
		'datiTerritoriali.enteTerritorialeSecondoLivelloUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.enteTerritorialeSecondoLivello.list", []).map(_assoc),
			{ listManagement: { name: 'EnteTerritorialeSecondoLivello', query_name: 'getRelatedAnagrafica' } },
		],
		'datiTerritoriali.ambitoTerritorialeUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.ambitoTerritoriale.list", []).map(_assoc),
			{ listManagement: { name: 'AmbitoTerritoriale', query_name: 'getRelatedAnagrafica' } },
		],
		'datiIstruzione.tipologiaScuolaFrequentataUniqueId': [
			..._get($common.related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaScuolaFrequentata.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaScuolaFrequentata', query_name: 'getRelatedAnagrafica' } },
		],
	});
	
	const { onChangeProvincia } = useChangeComuniByProvincia();
	const onChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;
		const toHide = {
			datiStraniero: _get(values, 'isStraniero') !== true,
			datiMinore: _get(values, 'isMinore') !== true,
		};
		Object.keys(toHide).forEach(name => {
			const node = getSchemaNode(name);
			if (node) {
				setSchemaNode(name, {
					...node,
					hidden: toHide[name],
				});
			}
		});
		['datiCorrispondenza'].forEach(ns => {
			const flag = _get(values, `${ns}.coincideConResidenza`);
			['denominazione', 'note', 'luogo.localita', 'luogo.comuneEstero', 'luogo.nazioneUniqueId', 'luogo.provinciaUniqueId', 'luogo.comuneUniqueId', 'luogo.cap'].forEach(k => {
				const name = `${ns}.${k}`;
				const node = getSchemaNode(name);
				if (node) {
					setSchemaNode(name, {
						...node,
						props: {
							...(node.props || {}),
							disabled: flag,
						},
						hidden: flag,
					});
				}
			});
		});
		['datiNascita', 'datiResidenza.luogo', 'datiCorrispondenza.luogo'].forEach(ns => {
			onChangeProvincia({ ns, ...args })
		});
	};

	const { schema: formSchema, ...gqlFilters } = $common.filters;
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
			...gqlFilters,
		},
		actions: {
			...$common.crud.actions,
		},
		results: {
			...$common.crud.results,
		},
		schema,
		dataDetails,
		extra: {
			slots: {
				edit: EditAssistito,
			},
		},
		methods: {
		  onFormInit: onChange,
		  onFormChange: onChange,
		},
		filters: {
			schema: formSchema,
		},
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: '',
					text: '',
					formatter: (cell: any, row) => {
						return (
							<Button variant='action'>
								<i className='bi bi-folder' onClick={() => navigate(`/cartelle/ricerca?assistitoUniqueId=${_get(row, 'uniqueId')}`)}>
									<span className='visually-hidden'>cartelle</span>{' '}
								</i>
							</Button>
						);
					},
				},
				{
					dataField: "datiAnagrafici.nome",
					text: "Nome",
				},
				{
					dataField: "datiAnagrafici.cognome",
					text: "Cognome",
				},
				{
					dataField: "datiAnagrafici.codiceFiscale",
					text: "Codice fiscale",
					hidden: isMobile,
				},
				{
					dataField: "datiAnagrafici.sesso",
					text: "Sesso",
					hidden: isMobile,
				},
				{
					dataField: 'datiAnagrafici.statoCivile.descrizione',
					text: 'Stato Civile',
					sort: true,
					hidden: true,
					dataNode: `datiAnagrafici {
						statoCivile { descrizione }
					}`,
				},
				{
					dataField: "datiNascita.data",
					text: "Data di nascita",
					formatter: Formatters.dateFormatter,
					sort: true,
					hidden: isMobile,
				},
				{
					dataField: 'datiNascita.comune.descrizione',
					text: 'Comune di nascita',
					sort: true,
					hidden: true,
					dataNode: `datiNascita {
						comune { descrizione }
					}`,
				},
				{
					dataField: 'datiResidenza.luogo.comune.descrizione',
					text: 'Comune di residenza',
					sort: true,
					hidden: true,
					dataNode: `datiResidenza {
						luogo { comune { descrizione } }
					}`,
				},
			],
		},
	};
};