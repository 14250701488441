import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Alert, ProgressBar, Row, Col, Accordion } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';
import { UTCToTZ } from 'src/@bootstrap/helpers';
import { setMessage } from 'src/@bootstrap/features/app';
import { formatDate } from 'src/app/helpers/helpers';

interface Props {
    erogatoreId: string;
    organizationId: string;
    onImport: () => void;
}

interface ImportStatus {
    id: string;
    userId: string;
    organizationId: string;
    insertDate: string;
    total: number;
    requestStart: string;
    importStart: string;
    importEnd: string;
    totalPlanCare: number;
    addedPlanCare: number;
    updatedPlanCare: number;
    errorPlanCare: number;
    status: number;
    totalProgressPercent: number;
    pianiIndividuali: any[];
}

// in seconds
const POLLING_FREQUENCY = 5;

/*
[status]
    da 1 a 3 è in corso un import
    4 conclusa correttamente, >4 conclusa ma con uno o più errori

DownloadJson = 1,
JsonReady = 2,
ImportInProgress = 3,
Imported = 4,
ImportedWithSomeErrors = 5,
Failed = 6,
TimeOut = 7
*/

const SGDT_IMPORT_STATUS = {
    1: 'DownloadJson',
    2: 'JsonReady',
    3: 'ImportInProgress',
    4: 'Imported',
    5: 'ImportedWithSomeErrors',
    6: 'Failed',
    7: 'TimeOut',
}

const Import = (props: Props) => {
    const { erogatoreId, organizationId, onImport } = props;
    const [busy, setBusy] = useState(false);
    const [importing, setImporting] = useState(false);
    const [imported, setImported] = useState<ImportStatus>();
    const dispatch = useDispatch();
    const $polling = useRef<any>();

	const { useRestRequest } = useDataRequest();
    const [execImport] = useRestRequest({
        method: 'POST',
        path: 'api/{ver}/sgdt/progetti-individuali/import',
		jwt: true,
	});
    const [execCheck] = useRestRequest({
        method: 'GET',
        path: 'api/{ver}/sgdt/progetti-individuali/import',
		jwt: true,
	});

    useEffect(() => {
		checkImport().then(ready => {
            if (!ready) {
                startPolling();
            }
        });

        return () => {
            stopPolling();
        }
    }, []);

    const checkImport = useCallback(async () => {
        try {
            const res = await execCheck({
                queryString: { erogatoreId, organizationId },
            }) as unknown as ImportStatus;
            if (res) {
				const { status } = res;
                setImported(res);
                onImport();
				if (status <= 3) {
					setImporting(true);
                    return false;
				} else {
					setImporting(false);
                    /* 
                    // silenced errors
                    if (status > 4) {
                        const ERR = {
                            5: `L'importazione si è conclusa ma con errori`,
                            6: `L'importazione è fallita`,
                            7: `L'importazione è andata in timeout`,
                        }
                        dispatch(setMessage({ type: 'error', text: ERR[status], modal: true }));
                    }
                    */
                }
                return true;
            }
        } catch(e) {}
    }, [erogatoreId, organizationId]);

    const startImport = useCallback(async () => {
        setBusy(true);
        try {
            const res: any = await execImport({
                data: { erogatoreId, organizationId },
            });
            if (res && res.error) {
                // refresh import
                await checkImport();
                throw new Error(res.errorMessage);
            }
            if (res && res.importUniqueId) {
                const ready = await checkImport();
                if (!ready) {
                    startPolling();
                }
            }
        } catch(e: any) {
            dispatch(setMessage({ type: 'error', text: e.message, modal: true }));
        }
        setBusy(false);
    }, [erogatoreId, organizationId]);

    const startPolling = useCallback(async () => {
        stopPolling();
        try {
            $polling.current = setInterval(async () => {
                const ready = await checkImport();
                if (ready) {
                    stopPolling();
                }
            }, POLLING_FREQUENCY * 1000);
        } catch(e) {}
    }, [checkImport]);

    const stopPolling = useCallback(() => {
        if ($polling.current) clearInterval($polling.current);
    }, []);
    
    if (!erogatoreId || !organizationId) return null;

    const lastImport = imported && imported.importEnd ? formatDate(UTCToTZ(imported.importEnd) || '', 'full') : undefined;
    return <Alert variant={'secondary'}>
        <Row>
            <Col>
                {importing && imported && <Fragment>
                <p>
                    È attualmente in corso una sincronizzazione con gli ultimi PI.<br />
                    Qui di seguito può vedere i dati importati fino a questo momento.
                </p>
                <p>
                    Stato di avanzamento: {imported.totalProgressPercent}%
                </p>
                </Fragment>}
                {!importing && imported && <Fragment>
                    <Accordion>
                        <Accordion.Item eventKey="lastImport">
                            <Accordion.Header>
                                <div className='d-flex justify-content-between flex-row bd-highlight gap-3'>
                                    <span>Ultima importazione:</span>
                                    <strong>{lastImport}</strong>
                                    <span>Stato:</span>
                                    <strong>{SGDT_IMPORT_STATUS[imported.status]} ({imported.totalProgressPercent}%)</strong>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <table className='table table-sm'><tbody>
                                    <tr><td><strong>Inizio importazione:</strong></td><td>{formatDate(UTCToTZ(imported.importStart) || '', 'full')}</td></tr>
                                    <tr><td><strong>Fine importazione:</strong></td><td>{formatDate(UTCToTZ(imported.importEnd) || '', 'full')}</td></tr>
                                    <tr><td><strong>PI aggiunti:</strong></td><td>{imported.addedPlanCare}</td></tr>
                                    <tr><td><strong>PI modificati:</strong></td><td>{imported.updatedPlanCare}</td></tr>
                                    <tr><td><strong>PI in errore:</strong></td><td>{imported.errorPlanCare}</td></tr>
                                    <tr><td><strong>PI totali:</strong></td><td>{imported.totalPlanCare}</td></tr>
                                    <tr><td><strong>Stato:</strong></td><td>
                                        <div>{SGDT_IMPORT_STATUS[imported.status]}</div>
                                        {imported.status < 4 && <ProgressBar now={imported.totalProgressPercent} label={`${imported.totalProgressPercent}%`} />}
                                    </td></tr>
                                </tbody></table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </Fragment>}
            </Col>
            <Col className='text-end'>
                <div className="d-grid gap-2">
                    {busy && <Button variant='success' size='lg' disabled>Importazione in avvio...</Button>}
                    {!busy && importing && <Button size='lg' disabled>Una procedura di import è in coda...</Button>}
                    {!busy && !importing && <Button variant='success' size='lg' onClick={startImport}>Avvia importazione degli ultimi progetti individuali</Button>}
                </div>
            </Col>
        </Row>
    </Alert>
}


export default Import;