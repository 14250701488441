import React, { useContext } from "react";
import { ConfigContext } from "src/@bootstrap/Config";
import { useIsSGDT } from "../views/sgdt/SGDT";

function fixSub(tree) {
    return tree.map(data => ({
        ...data,
        children: (data.sub || []).filter(c => !!c),
    })).filter(c => !!c);
}

interface Props {
    onInit?: (navigations: any) => void;
    onRender: (navigations: any) => JSX.Element;
}

const Navigation = (props: Props) => {
    const { onInit, onRender } = props;
    const tree = useNavigationTree();
    if (onInit) {
        onInit(tree);
    }

    return onRender(tree);
};

export const useNavigationTree = () => {
    const { getEnv } = useContext(ConfigContext);

    const isEnabled = (module: Record<string, any>) => {
        return ((getEnv('HIDE_PATHS') as unknown as string[]) || []).includes(module.path) ? undefined : module;
    }
    const isSGDT = useIsSGDT();
    return fixSub([
        {
          name: "Dashboard",
          type: "link",
          icon: "i-Bar-Chart",
          path: 'dashboard/supervisor/DashboardSupervisor',
        },
        {
          name: "Anagrafiche",
          type: "dropDown",
          icon: "i-Address-Book",
          sub: [
            {
              name: "Assistiti",
              path: "/anagrafiche/assistiti",
              type: "link",
              permissions: [
                ['VisualizzazioneAssistitoGlobale']
              ]
            }, {
              name: "Medici",
              path: "/anagrafiche/medici",
              type: "link",
              permissions: [
                ['VisualizzazioneMedicoGlobale']
              ]
            }, {
              name: "Operatori",
              path: "/anagrafiche/operatori",
              type: "link",
              permissions: [
                ['VisualizzazioneOperatoreGlobale', 'VisualizzazioneOperatoreLocale']
              ]
            }, 
            /*{
              icon: "i-File-Block",
              name: "Consensi informati mancanti",
              path: "/anagrafiche/consensi-informati-mancanti",
              type: "link"
            },
            {
              icon: "i-Download1",
              name: "Riconciliazione",
              path: "/",
              type: "link"
            },
            {
              icon: "i-Link-2",
              name: "Fusione",
              path: "/",
              type: "link"
            }*/
          ]
        },
        {
          name: "Strutture e Servizi",
          type: "dropDown",
          icon: "i-University",
          sub: [
            {
              name: "Erogatori",
              path: "/strutture-servizi/erogatori",
              type: "link",
              permissions: [
                ['VisualizzazioneErogatoreGlobale', 'VisualizzazioneErogatoreLocale']
              ]
            },
            {
              name: "Servizi",
              path: "/strutture-servizi/servizi",
              type: "link",
              permissions: [
                ['VisualizzazioneServizioGlobale']
              ]
            },
            {
              name: "Prestazioni",
              path: "/strutture-servizi/prestazioni",
              type: "link",
              permissions: [
                ['VisualizzazionePrestazioneGlobale']
              ]
            },
            isEnabled({
              name: "Strutture sanitarie",
              path: "/strutture-servizi/struttureSanitarie",
              type: "link",
              permissions: [
                ['VisualizzazioneStrutturaSanitariaGlobale']
              ]
            }),
          ]
        },
        !isSGDT ? {
          name: "Cartelle",
          path: "/cartelle/ricerca",
          type: "link",
          icon: "i-Folders",
          permissions: [
            ['VisualizzazioneCartellaGlobale', 'VisualizzazioneCartellaLocale']
          ],
        } : {
          name: "Cartelle",
          type: "dropDown",
          icon: "i-Folders",
          sub: [
            {
              name: "Ricerca",
              path: "/cartelle/ricerca",
              type: "link",
              permissions: [
                ['VisualizzazioneCartellaGlobale', 'VisualizzazioneCartellaLocale']
              ]
            },
            {
              name: "SGDT",
              path: "/cartelle/sgdt",
              type: "link",
            },
          ],
        },
        {
          name: "Calendario",
          path: "/accessi/calendario",
          type: "link",
          icon: "i-Calendar-4",
          permissions: [
            ['GestioneAccessoGlobale', 'GestioneAccessoBase', 'RendicontazioneAccessoBase']
          ]
        },
        {
          name: "Reports",
          type: "dropDown",
          icon: "i-Bar-Chart-3",
          sub: [
            {
              icon: "i-Bar-Chart-3",
              name: "Diario",
              path: "/reports/diario",
              type: "link",
            },
            {
              icon: "i-Bar-Chart-3",
              name: "Accessi",
              path: "/reports/accessi",
              type: "link",
            },
            {
              icon: "i-Bar-Chart-3",
              name: process.env.REACT_APP_CONFIG?.indexOf('csi') === -1 ? 'PAI' : 'Progetti',
              path: "/reports/affidamento",
              type: "link",
            },
          ]
        },
        {
          name: "Configurazioni",
          type: "dropDown",
          icon: "i-Gear",
          sub: [
            {
              icon: "i-Business-Mens",
              name: "Account",
              path: "/users/list",
              type: "link",
              permissions: [
                ['ViewUsers']
              ]
            },
            {
              icon: "i-Management",
              name: "Ruoli",
              path: "/config/roles/list",
              type: "link",
              permissions: [
                ['ViewRoles']
              ]
            },
            {
              icon: "i-Unlock-2",
              name: "Permessi",
              path: "/config/permissions/main",
              type: "link",
              permissions: [
                ['ViewRoleClaims']
              ]
            },
            {
              icon: "i-Folders",
              name: "Elenchi",
              path: "/config/lists",
              type: "link",
              permissions: [
                ['VisualizzazioneElencoGlobale']
              ]
            },
            {
              icon: "i-Business-Mens",
              name: "Account operatori",
              path: "/config/accountOperatori/list",
              type: "link",
              permissions: [
                ['ViewUsers']
              ]
            },
            isSGDT ? {
              icon: "i-Gear",
              name: "SGDT",
              type: "dropDown",
              sub: [
                {
                  name: "Configurazione",
                  path: "/config/sgdt",
                  type: "link",
                },
                {
                  name: "Logs",
                  path: "/config/sgdt/logs",
                  type: "link",
                },
              ]
            } : undefined,
          ]
        }
        /*
        {
          name: "Accesso e orientamento",
          type: "dropDown",
          icon: "i-Speach-Bubble-3",
          role: "ad345c55-5109-11ec-bf63-0242ac134442",
          sub: [
            {
              icon: "i-Add-File",
              name: "Inserimento",
              path: "/",
              type: "link"
            },
            {
              icon: "i-File-Search",
              name: "Ricerca",
              path: "/",
              type: "link"
            }
          ]
        },
        {
          name: "Strutture e servizi",
          type: "dropDown",
          icon: "i-University1",
          sub: [
            {
              icon: "i-University",
              name: "Strutture",
              path: "/",
              type: "link"
            },
            {
              icon: "i-Shopping-Bag",
              name: "Servizi",
              path: "/",
              type: "link"
            },
            {
              icon: "i-Consulting",
              name: "Operatori",
              path: "/",
              type: "link"
            },
            {
              icon: "i-Network-Window",
              name: "Procedimenti",
              path: "/",
              type: "link"
            }
          ]
        },
        */
    ]);
};

export default Navigation;