import { gql } from "@ai4/data-request";
import { ANAGRAFICA_FIELDS, LUOGO_FRAGMENT } from "./anagrafica";

export const GET_ASSISTITO = gql`
    query getAssistito($id: Guid!) {
        anagraficaQuery {
            assistiti {
                list (where: { uniqueId: { _eq: $id } }) {
                    ${ANAGRAFICA_FIELDS}
                    codiceUtente
                    
                    datiValidazioneCodiceFiscale {
                        isCodiceFiscaleTemporaneo
                        isCodiceFiscaleValidato
                    }
                    datiDecesso {
                        data
                        note
                        tipologiaStrutturaDecesso {
                            uniqueId
                        }
                        motivoDecesso {
                            uniqueId
                        }
                    }
                    datiCittadinanza {
                        tipologiaCittadinanza {
                            uniqueId
                            descrizione
                        }
                        cittadinanza {
                            uniqueId
                            descrizione
                        }
                        note
                    }
                    datiStraniero {
                        dataInizioResidenza
                        dataResidenzaContinuativa
                        documentoSoggiorno {
                            tipologiaDocumentoSoggiorno {
                                uniqueId
                            }
                            tipologiaPermesso {
                                uniqueId
                            }
                            motivoAccesso {
                                uniqueId
                            }
                            numeroDocumento
                            dataRilascio
                            dataScadenza
                            tipologiaEnteEmittente {
                                uniqueId
                            }
                            tipologiaEnteEmittenteAltro
                            nomeEnteEmittente
                            nazioneProvenienza {
                                uniqueId
                            }
                            comuneEsteroProvenienza
                            dataProvenienza
                        } 
                    }
                    datiMinore {
                        tutelaMinore {
                            uniqueId
                        }
                        condizioneMinore {
                            uniqueId
                        }
                        note
                    }
                    datiTerritoriali {
                        enteTerritorialePrimoLivello {
                            uniqueId
                        }
                        enteTerritorialeSecondoLivello {
                            uniqueId
                        }
                        ambitoTerritoriale {
                            uniqueId
                        }
                    }
                    datiIstruzione {
                        tipologiaScuolaFrequentata {
                            uniqueId
                        }
                        titoloDiStudio {
                            uniqueId
                        }
                        denominazioneScuolaFrequentata
                        note
                    }
                    datiOccupazione {
                        statusOccupazione {
                            uniqueId
                        }
                        statoCondizioneLavorativa {
                            uniqueId
                        }
                        statoServiziPerImpiego {
                            uniqueId
                        }
                        enteErogatoreServiziPerImpiego
                        statoContributoNASPI {
                            uniqueId
                        }
                        valoreNASPI
                        dataInizioDissocupazione
                        statoPattoDiLavoro {
                            uniqueId
                        }
                        statoDichiarazioneDisponibilita {
                            uniqueId
                        }
                    }
                    medicoDiFamiglia {
                        uniqueId
                    }
                    datiSanitari {
                        isInvalidoCivile
                        percentualeInvalidita
                        classeInvalidita {
                            uniqueId
                        }
                        certificazioneDisabilita {
                            uniqueId
                        }
                        tessereSanitarie {
                            tipologiaTesseraSanitaria {
                                uniqueId
                            }
                            dataScadenza
                            datiAnagrafici {
                                nome
                                cognome
                                codiceFiscale
                                sesso
                                statoCivile {
                                    uniqueId
                                }
                            }
                            datiNascita {
                                data
                                nazione {
                                    uniqueId
                                }
                                provincia {
                                    uniqueId
                                }
                                comune {
                                    uniqueId
                                }
                                comuneEstero
                                note
                            }
                            numeroIdentificazionePersonale
                            numeroIdentificazioneTessera
                            numeroIdentificazioneIstituzione
                            codiceRegionaleIndividuale
                            codiceIdentificativoENI
                        }
                    }
                }
            }
        }
    }

    ${LUOGO_FRAGMENT}
`;