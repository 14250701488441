import _get from 'lodash/get';
import React, { Fragment, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import JsonFormatter from 'react-json-formatter'
import { formatDate } from 'src/@bootstrap/components/Crud/helpers';
import { camelCaseToWords } from './ProgettoIndividuale';
import { DataContext, useDataRequest, useFileRequest } from '@ai4/data-request';

const RecordContext = createContext<any>(null);

export function LogView(props: any) {
    const {
        children,
        record,
        dirty,
        show,
        extraData,
        onAddExtraData,
        handleClose,
        handleClosed,
    } = props;

    const [data, setData] = useState<any>();
    const { useRestRequest } = useDataRequest();
	const [req] = useRestRequest({
        path: `api/{ver}/sgdt/logs/${record.uniqueId}`,
        method: 'GET',
        jwt: true,
    });
    useEffect(() => {
        req().then(res => {
            setData(res);
        });
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            onExited={handleClosed}
        >
            <Modal.Body>
                {data && <Fragment>
                <RecordContext.Provider value={data}>
                    <Row>
                        <Cell field='uniqueId' />
                        <Cell field='id' />
                        <Cell field='level' />
                        <Cell field='timestamp' type='datetime' />
                        <Cell field='message' />
                        <Cell field='note' />
                        <Cell field='referenceType' />
                        <Cell field='referenceValue' />
                        <Cell field='userId' />
                        <Cell field='operation' />
                        <Cell field='direction' />
                        <Cell field='request' block />
                    </Row>

                    {record.haveContent && <Fragment>
                        <Row>
                            <Cell field='contentType' />
                            <Col sm={6} className='mb-1'>
                                <div><strong>Content</strong></div>
                                <ContentDownloader {...data} />
                                {/*<div style={{ maxHeight: '200px', overflow: 'auto', }}>
                                    <JsonFormatter json={record.content} tabWith={4} jsonStyle={{
                                        propertyStyle: { color: 'red' },
                                        stringStyle: { color: 'green' },
                                        numberStyle: { color: 'darkorange' }
                                    }} />
                                </div>*/}
                            </Col>
                        </Row>
                    </Fragment>}
                </RecordContext.Provider>
                </Fragment>}
            </Modal.Body>
        </Modal>
    );
}

interface ContentDownloaderProps {
    uniqueId: string;
    timestamp: string;
    content: string;
    mimeType: string;
    fileExtension: string;
}

function ContentDownloader(props: ContentDownloaderProps) {
    const { uniqueId, timestamp, content, mimeType, fileExtension } = props;
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);

    return <a href={url} download={`${timestamp}${fileExtension}`} className='btn btn-primary btn-sm'>Scarica file {fileExtension.replace('.', '')}</a>
}

interface CellProps {
    data?: any;
    label?: string;
    field: any;
    type?: 'text' | 'datetime';
    block?: boolean;
}

function Cell(props: CellProps) {
    const { data, label, field, type = 'text', block } = props;
    const defaultData = useContext(RecordContext);
    const defaultLabel = camelCaseToWords(field);
    let value = _get(data || defaultData, field);
    if (value && type === 'datetime') {
        value = formatDate(value, 'full');
    }
    if (value && typeof value !== 'string') {
        value = JSON.stringify(value);
    }

    return <Col sm={block ? 12 : 6} className='mb-1'>
        <div><strong>{label || defaultLabel}</strong></div>
        <div style={{ overflowWrap: "break-word" }}>{value || '-'}</div>
    </Col>;
}

export default LogView;
