import _get from 'lodash/get';
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { selectMessage, setMessage } from "src/@bootstrap/features/app";

function Messages() {
    const message = useSelector(selectMessage);
    const dispatch = useDispatch();

    const { type, title, text, modal, onClose } = message || {};
    const _content = useMemo(() => {
        if (!text) return undefined;
        let ret = [] as string[];
        if (typeof text === 'string') {
            ret.push(text);
        } else {
            ret = text.map((error) => {
                if (typeof error === 'string') {
                    return error;
                }
                return error.message;
            });
        }
        return ret;
    }, [text]);

    const _title = useMemo(() => {
        if (title) return title;
        if (type === 'info') return 'Info';
        if (type === 'success') return 'Operazione completata';
        if (type === 'error') return 'Errore';
    }, [title, type]);

    const _onClose = useCallback(() => {
        dispatch(setMessage(undefined));
        if (onClose) onClose();
    }, [onClose]);

    if (!message) return null;

    if (modal) {
        return <Modal
            show
            centered
            onHide={_onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <strong className={`me-auto ${type === 'error' ? 'text-danger' : (type === 'success' ? 'text-success' : '')}`}>{_title}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {_content && _content.map(text => <div>{text}</div>)}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={_onClose}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
    }

    return createPortal(
        <ToastContainer className="p-3" style={{zIndex: 2000}} position={"bottom-end"}>
          <Toast onClose={_onClose} delay={3000} autohide>
            <Toast.Header>
                <strong className={`me-auto ${type === 'error' ? 'text-danger' : (type === 'success' ? 'text-success' : '')}`}>{_title}</strong>
            </Toast.Header>
            <Toast.Body>{_content && _content.map(text => <div>{text}</div>)}</Toast.Body>
          </Toast>
        </ToastContainer>,
        document.body
    );
}

export default Messages;