import _get from 'lodash/get';
import React, { useCallback, useState } from "react";
import { Button } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';
import { FormViewer } from '@ai4/form-viewer';
import $rivalutazione from '../schema/rivalutazione.json';

interface Props {
    record: any;
}

export function Rivalutazione(props: Props) {
    const {
        record,
    } = props;
    const [sent, setSent] = useState(false);

    const { useRestRequest } = useDataRequest();
    const [exec, res] = useRestRequest({
        method: 'POST',
        path: `api/{ver}/sgdt/progetti-individuali/${record.uniqueId}/richiesta-rivalutazione`,
		jwt: true,
	});
    const canSend = !record.rivalutazione && !sent;

    const send = useCallback(async (values: any) => {
        try {
            await exec({
                data: {
                    note: values.rivalutazioneNote,
                },
            });
            setSent(true);
        } catch(e) {}
    }, []);

    return (
        <div className="high-textarea">
            <FormViewer
                schema={$rivalutazione as any}
                initialValues={{
                    rivalutazioneNote: record.rivalutazioneNote || ''
                }}
                onSubmit={(args) => {
                    const { values, form } = args;
                    send(values);
                }}
                slots={{
                    ButtonBar: (args) => (
                        <>
                            <div className="d-flex justify-content-end flex-row bd-highlight gap-3">
                                {canSend && <Button variant="primary" type="submit" disabled={res.loading}>
                                    Invia richiesta di rivalutazione
                                </Button>}
                            </div>
                        </>
                    ),
                }}
            />
        </div>
    );
}

export default Rivalutazione;
