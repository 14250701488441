import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';
import { ConfigContext } from 'src/@bootstrap/Config';
import AuthFlow from './components/AuthFlow';
import List from './SGDTProgettiIndividuali';
import Filters from './components/Filters';

export const useIsSGDT = () => {
    const { getEnv } = useContext(ConfigContext);
    return !((getEnv('HIDE_PATHS') as unknown as string[]) || []).includes('/cartelle/sgdt');
}

const SGDT = () => {
    const [currentFilters, setCurrentFilters] = useState<any>();
    const erogatoreId = _get(currentFilters, 'erogatoreId');
    const organizationId = _get(currentFilters, 'organizationId');
    const [busy, setBusy] = useState(false);
    const [auth, setAuth] = useState(false);

	const { useRestRequest } = useDataRequest();
    const [execCheckToken] = useRestRequest({
        method: 'GET',
        path: '@later',
		jwt: true,
	});

    const checkToken = useCallback(async (erogatoreId: string) => {
        setBusy(true);
        try {
            const res: any = await execCheckToken({
                path: `api/{ver}/sgdt/auth/erogatori/${erogatoreId}/token`
            });
            if (res && res.result === true) {
                setAuth(true);
            }
        } catch(e) {

        }
        setBusy(false);
    }, []);

    useEffect(() => {
        if (erogatoreId) {
            checkToken(erogatoreId);
        }
    }, [erogatoreId]);

    return <Fragment>
        <Filters onChange={(filters) => setCurrentFilters(filters)} />
        {erogatoreId && organizationId && <Fragment>
            {busy && <Spinner />}
            {!auth && <AuthFlow erogatoreId={erogatoreId} onSuccess={() => setAuth(true)} />}
            {auth && <List filters={currentFilters} />}
        </Fragment>}
    </Fragment>
}

export default SGDT;