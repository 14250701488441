import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/@bootstrap/services/store';
import { Errors } from "src/@bootstrap/types";

interface Message {
    type: 'info' | 'success' | 'error';
    modal?: boolean;
    title?: string;
    text: string | Errors;
    onClose?: () => void;
}
interface State {
    message?: Message;
};


const initialState: State = {
    
};

export const reducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<Message | undefined>) => {
            state.message = action.payload;
        },
    },
});

export const { setMessage } = reducer.actions;

export const selectMessage = (state: RootState) => state.app.message as Message | undefined;

export default reducer.reducer
