import React, {
    Fragment, useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { selectVersionInfo, setVersionInfo } from "../features/custom";
import { useDataRequest } from "@ai4/data-request";

function Version() {
    const dispatch = useDispatch();
    const versions = useSelector(selectVersionInfo);
    const { useRestRequest } = useDataRequest();
    const [reqVer, ] = useRestRequest({
        path: 'api/{ver}/about/version',
        method: 'GET',
        jwt: true,
    });
    useEffect(() => {
        reqVer().then(res => {
            dispatch(setVersionInfo(res as any));
        })
    }, [])
    
    if (!versions) return null;

    const version = versions.application;
    const verInfoPopup = (
        <Popover id="ver-info">
            <table className="table mb-0">
                <tbody>
                    <tr>
                        <td>Version</td>
                        <td>{version.version}</td>
                    </tr>
                    <tr>
                        <td>Build</td>
                        <td>{version.build}</td>
                    </tr>
                    <tr>
                        <td>Revision</td>
                        <td>{version.revision}</td>
                    </tr>
                </tbody>
            </table>
        </Popover>
      );
    
    return <Fragment>
        {version && <div className="text-center"><OverlayTrigger trigger="click" placement="right" overlay={verInfoPopup}><small style={{ cursor: 'pointer' }}>{version.version}</small></OverlayTrigger></div>}
    </Fragment>
}

export default Version;