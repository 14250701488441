import { DateTime } from "luxon";

export function getTimeDifference(date) {
    const now = DateTime.now();
    const end = DateTime.fromFormat(date, 'DD/MM/YYYY HH:mm:ss');
    const difference = end.diff(now).toMillis() / 1000;
    
    if (difference < 60) return `${Math.floor(difference)} seconds`;
    else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`;
    else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`;
    else if (difference < 86400 * 30)
        return `${Math.floor(difference / 86400)} days`;
    else if (difference < 86400 * 30 * 12)
        return `${Math.floor(difference / 86400 / 30)} months`;
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`;
}

export function getQueryParam(param: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
}

export function classList(classes) {
    return Object.entries(classes)
        .filter(entry => entry[1])
        .map(entry => entry[0])
        .join(" ");
}

/**** TimeZones conversion */
export function fullDateTimeToISO(str: string | null) {
	return (str || '').substring(0, '0000-00-00T00:00:00'.length);
}

export function browserTimeZone() {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (browserTimeZone) {
        return browserTimeZone;
    }
    return undefined;
}

export function UTCToTZ(date: string, timeZone?: string) {
    if (!timeZone) timeZone = browserTimeZone();
	const utcDate = DateTime.fromISO(date, { zone: 'UTC' });
	const tzDate = utcDate.setZone(timeZone);
	return tzDate.toISO();
}

export function TZToUTC(date: string, timeZone?: string) {
    if (!timeZone) timeZone = browserTimeZone();
	const tzDate = DateTime.fromISO(date, { zone: timeZone });
	const utcDate = tzDate.setZone('UTC');
	return utcDate.toISO();
}
