import React, { Suspense } from 'react';
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { Props as DataProviderProps } from '@ai4/data-request/dist/components/DataContext';
import { AppProvider } from 'src/@bootstrap/context';
import { getStore } from 'src/@bootstrap/services/store';
import Data from 'src/@bootstrap/Data';
import { Route, Theme } from 'src/@bootstrap/types';
import { Loading } from 'src/@bootstrap/template/@gull';
import "src/@bootstrap/template/styles/app/app.scss";

interface Props {
    routes: Route[];
    theme: Theme;
    dataProvider: Partial<DataProviderProps>;
}

function Root(props: Props) {
    const { routes, theme, dataProvider } = props;

    return (
        <AppProvider value={{ routes, theme }}>
            <Provider store={getStore()}>
                <Suspense fallback={<Loading />}>
                    <Router>
                        <Data {...dataProvider}>
                            <Routes routes={routes} />
                        </Data>
                    </Router>
                </Suspense>
            </Provider>
        </AppProvider>
    );
}

function Routes(props: {routes: Route[]}) {
    return useRoutes(props.routes);
}

export default Root;
