import _get from 'lodash/get';
import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, Tabs, Tab, Button, Spinner } from 'react-bootstrap';
import { gql, useDataRequest } from "@ai4/data-request";
import { useSelectItemsPopulate } from 'src/@bootstrap/components/Crud/data';
import { assoc } from 'src/@bootstrap/components/Crud/helpers';
import $filters from '../schema/filters.json';
import { FormViewer } from '@ai4/form-viewer';

const RELATED = gql`
    query getRelatedSGDTFilters {
		struttureServiziQuery {
            erogatori {
                list {
                    uniqueId
                    nome
                }
            }
			struttureSanitarie {
				list {
					uniqueId
					nome
				}
			}
		}
	}
`;

interface Props {
    onChange: (filters: any) => void;
}

const Filters = (props: Props) => {
    const { onChange } = props;
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);

    const erogatori = _get(related.data, "struttureServiziQuery.erogatori.list", []);
    const erogatoreId = erogatori.length > 0 ? erogatori[0].uniqueId : undefined;
    useEffect(() => {
        if (erogatoreId) {
            onChange({ erogatoreId });
        }
    }, [erogatoreId]);

    const filterSchema = useSelectItemsPopulate(
        $filters as any,
        related, {
            erogatoreId: erogatori.map(assoc),
            organizationId: _get(related.data, "struttureServiziQuery.struttureSanitarie.list", []).map(assoc),
        }
    );

    if (related.loading) return <Spinner />;
    return <Fragment>
        {filterSchema && <Accordion className="mb-3" defaultActiveKey={"sgdtFilters"}>
            <Accordion.Item eventKey="sgdtFilters">
                <Accordion.Header>Ricerca</Accordion.Header>
                <Accordion.Body>
                    <FormViewer
                        schema={filterSchema as any}
                        initialValues={{
                            erogatoreId,
                        }}
                        onSubmit={(args) => {
                            const { values, form } = args;
                            const filters = { ...values, t: Date.now() };
                            onChange(filters);
                        }}
                        slots={{
                            ButtonBar: ({form}) => (
                                <>
                                    <div className="d-flex justify-content-end flex-row bd-highlight gap-3">
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                form.resetForm({});
                                                form.submitForm();
                                            }}
                                        >
                                            Annulla filtro
                                        </Button>
                                        <Button variant="primary" type="submit" disabled={!form.isValid}>
                                            Applica
                                        </Button>
                                    </div>
                                </>
                            ),
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>}
    </Fragment>
}

export default Filters;