import React, {
    useState,
    useCallback,
    Fragment,
    useContext,
    useEffect,
    Component,
    Suspense,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { AppContext } from "src/@bootstrap/context";
import { setLayoutSettings, setDefaultSettings, selectSettings } from "src/@bootstrap/features/layout";
import { loggedOut, selectAuthenticated, selectCurrentUser } from "src/@bootstrap/features/auth";

import { GullLayouts } from "./index";
import Customizer from "src/@bootstrap/template/@gull/layout/SharedComponents/Customizer";
import { matchRouteByID } from "src/@bootstrap/services/router";
import { useAuthToken, useDataRequest } from "@ai4/data-request";
import { manageList, selectCurrentList, setLists } from "src/app/features/custom";
import GestioneListe from "../views/commons/GestioneListe";

function AppLayout() {
    const { routes } = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    const settings = useSelector(selectSettings);
    const auth = useSelector(selectAuthenticated);
    const user = useSelector(selectCurrentUser);
    const currentList = useSelector(selectCurrentList);
    const dispatch = useDispatch();
	const { forget } = useAuthToken();
    
    const { useRestRequest } = useDataRequest();
    const [reqElenchi, ] = useRestRequest({
        path: 'api/{ver}/elenchi',
        method: 'GET',
        jwt: true,
    });

    useEffect(() => {
        if (auth) {
            reqElenchi().then(res => {
                dispatch(setLists(res as any));
            });
        }
    }, [auth])

    const initAppDirection = useCallback(() => {
        setTimeout(() => {
            document.documentElement.setAttribute("dir", settings.dir);
        });
    }, [settings]);

    const logout = useCallback(() => {
        forget();
        dispatch(loggedOut());
        setTimeout(() => {
            navigate("/session/signin");
        });
    }, []);

    useEffect(() => {
        initAppDirection();
    }, [initAppDirection]);

    useEffect(() => {
        // initCodeViewer();
        // this.updateSettingsFromRouter();
    }, [location.pathname]);

    const Layout = GullLayouts[settings.activeLayout];
    const matched = matchRouteByID(routes, 'root');
    return (
        <Suspense fallback={null}>
            <React.Fragment>
                <Layout user={user} routes={matched!.children} onLogout={logout}></Layout>
                {/* settings && settings.customizer.show && <Customizer /> */}
                
                <Modal show={!!currentList} onHide={() => dispatch(manageList(undefined))}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modifica lista</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentList && <GestioneListe {...currentList} />}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </Suspense>
    );
}

export default AppLayout;
