import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';

interface Props {
    erogatoreId: string;
    onSuccess: () => void;
}

// in seconds
const POLLING_FREQUENCY = 5;
const POLLING_TIMEOUT = 120;

const AuthFlow = (props: Props) => {
    const { erogatoreId, onSuccess } = props;
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState<string>();
    const dispatch = useDispatch();
    const $polling = useRef<any>();
    const $timeout = useRef<any>();
    const $popup = useRef<Window>();

	const { useRestRequest } = useDataRequest();
    const [execInit] = useRestRequest({
        method: 'POST',
        path: `api/{ver}/sgdt/auth/erogatori/${erogatoreId}/session`,
		jwt: true,
	});
    const [execCheck] = useRestRequest({
        method: 'GET',
        path: '@later',
		jwt: true,
	});

    useEffect(() => {
        return () => {
            stopPolling();
        }
    }, []);

    const stopPolling = useCallback(() => {
        if ($polling.current) clearInterval($polling.current);
        if ($timeout.current) clearTimeout($timeout.current);
        if ($popup.current) $popup.current.close();
    }, []);

    const checkSession = useCallback(async (sid: string) => {
        try {
            const res: any = await execCheck({
                path: `api/{ver}/sgdt/auth/erogatori/${erogatoreId}/session/${sid}`
            });
            if (res && res.result === true) {
                stopPolling();
                onSuccess();
            } else if (res && res.status === 'Error') {
                stopPolling();
                setError(`L'autenticazione non è andata a buon fine.`);
            }
        } catch(e) {
            // silence error, we'll retry
        }
    }, []);

    const startAuth = useCallback(async () => {
        setBusy(true);
        stopPolling();
        try {
            const res: any = await execInit();
            if (res && res.result === true) {
                const { sessionId, url } = res;

                const handle = window.open(url, "SGDTAuth"/*, "left=0,top=0,width=700,height=700"*/);
                if (handle) {
                    $popup.current = handle;
                } else {
                    setError(`Non è stato possibile aprire la finestra di autenticazione.`);
                }

                $polling.current = setInterval(() => {
                    checkSession(sessionId);
                }, POLLING_FREQUENCY * 1000);
                $timeout.current = setTimeout(() => {
                    setError(`L'autenticazione non è stata completata sul sito esterno.`);
                    stopPolling();
                    setBusy(false);
                }, POLLING_TIMEOUT * 1000);
            }
        } catch(e) {
            setError(`Impossibile iniziare il processo di autenticazione`);
        }
    }, []);
    
    return <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
    >
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>
                    {error && <>Errore nell'autenticazione</>}
                    {!error && <Fragment>
                        {!busy && <>Autenticazione</>}
                        {busy && <>Autenticazione in corso...</>}
                    </Fragment>}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {error && <>{error}</>}
                {!error && <Fragment>
                    {!busy && <p>Non sei autenticato. Accedi al servizio SISS della Regione Lombardia.</p>}
                    {busy && <p>Completa l'accesso al SISS nella piattaforma della Regione Lombardia.</p>}
                </Fragment>}
            </Modal.Body>

            <Modal.Footer>
                {error && <Button variant="primary" onClick={startAuth}>Riprova</Button>}
                {!error && <Fragment>
                    {!busy && <Button variant="primary" onClick={startAuth}>Accedi</Button>}
                    {busy && <Button variant="primary" onClick={startAuth}>Avvia un'altra sessione di autenticazione</Button>}
                </Fragment>}
            </Modal.Footer>
        </Modal.Dialog>
    </div>
}


export default AuthFlow;