import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useEffect } from 'react';
import { useDataRequest } from "@ai4/data-request";
import RecordsManagement from '@ai4/records-management';
import { dateFormatter, boolFormatter } from 'src/app/helpers/formatters';
import EditProgettoIndividuale from './edit/ProgettoIndividuale';

interface Props {
    cartellaId: string;
}

const CartellaList = (props: Props) => {
    const { cartellaId } = props;
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/sgdt/progetti-individuali',
        method: 'GET',
        jwt: true,
    });
    useEffect(() => {
        reqList({
            queryString: props,
        });
    }, []);
	const rows = _get(resList, 'data.data', []);

    const args = {
        module,
		entity: 'cartella-progetti-individuali',
		columns: [
            {
                dataField: "numeroPratica",
                text: "Numero pratica",
            },
            {
                dataField: "periodStart",
                text: "Data inizio",
                formatter: dateFormatter
            },
            {
                dataField: "codePI",
                text: "Codice",
            },
            {
                dataField: "importLastUpdated",
                text: "Data aggiornamento SGDT",
                formatter: dateFormatter
            },
            {
                dataField: "versione",
                text: "Versione SGDT",
            },
            {
                dataField: "rivalutazione",
                text: "Rivalutazione",
                formatter: boolFormatter
            },
		],
		rows,
		dataLoading: resList.loading,
		formSchema: {},
		submitResponse: null,
		// hideEditColumns: true,
        clickRowToSelect: true,
		slots: {
			header: ({ onCreate }) => null,
            edit: EditProgettoIndividuale,
		},
        extra: {
            cartellaUniqueId: cartellaId,
        },
        checkFeature: (feature, row) => {
            return feature === 'edit';
        },
		onBoot: () => {},
		onInit: () => {},
		onChange: () => {},
		onSubmit: () => {},
		onCancel: () => {},
		// onSelectedRow: () => {},
		onDeleteRow: () => {},
		onAddExtraData: () => {},
    }
    
    return <Fragment>
        {/* @ts-ignore */}
        <RecordsManagement {...args} />
    </Fragment>
}


export default CartellaList;