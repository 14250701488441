import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { setMessage } from "src/@bootstrap/features/app";
import { useDataDecorator, useSelectItemsPopulate } from 'src/app/helpers/data';
import $schema from './schema/edit/presaInCarico.json';
import { GET_CARTELLA } from './gql/cartella';
import { GET_PRESA_IN_CARICO } from './gql/presaInCarico';
import { _assoc } from '../anagrafiche/common';

const RELATED = gql`
	query getRelatedPresaInCarico {
		cartellaSocioSanitariaQuery {
            elenchiCartellaSocioSanitaria {
                motivoPresaInCarico {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                esitoPresaInCarico {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
        struttureServiziQuery {
            erogatori {
                list {
                    uniqueId
                    nome
                }
            }
        }
	}
`;

export function EditSchedaPresaInCarico(props: any) {
    const { parent, onBack } = props;
    const { uniqueId, schede } = parent;
    
    const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const [execSave, res] = useRestRequest({
        path: `api/{ver}/cartellasociosanitaria/cartelle/${uniqueId}/presa-in-carico`,
        jwt: true,
    });
    const [execRemove] = useRestRequest({
        path: `api/{ver}/cartellasociosanitaria/cartelle/${uniqueId}/presa-in-carico`,
        method: 'DELETE',
        jwt: true,
    });
    const dispatch = useDispatch();

    const related = useQuery(RELATED);
    const current = schede.find(s => s.tipologia.toUpperCase() === 'PRESAINCARICO');
    const query = useQuery(GET_PRESA_IN_CARICO, {
        skip: !current,
        variables: {
            id: current ? current.uniqueId : undefined,
        }
    });
    const data = useDataDecorator('cartellaSocioSanitariaQuery.schedaPresaInCarico.list', query.data);
    const record = useMemo(() => {
        const res = data.length > 0 ? data[0] : {};
        return {
            ...res,
            motivoPresaInCaricoUniqueId: res.motivoUniqueId,
            esitoPresaInCaricoUniqueId: res.esitoUniqueId,
        }
    }, [data]);

    const schema = useSelectItemsPopulate(
        $schema as unknown as any,
        related,
        {
            motivoPresaInCaricoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.motivoPresaInCarico.list', []).map(_assoc),
                { listManagement: { name: 'MotivoPresaInCarico', query_name: 'getRelatedPresaInCarico' } },
            ],
            esitoPresaInCaricoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.esitoPresaInCarico.list', []).map(_assoc),
                { listManagement: { name: 'EsitoPresaInCarico', query_name: 'getRelatedPresaInCarico' } },
            ],
            erogatoreUniqueId: [
                ..._get(related.data, 'struttureServiziQuery.erogatori.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
        }
    );

    const remove = useCallback(async () => {
        await execRemove();
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [GET_CARTELLA],
            });
        }
        dispatch(
            setMessage({ type: 'success', text: 'Dati rimossi correttamente.' })
        );
        onBack();
    }, [record]);

    const submit = useCallback(async ({ values }) => {
        await execSave({
            method: record && record.uniqueId ? 'PUT' : 'POST',
            data: {
                dati : {
                    ...values,
                },
            },
        });
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [GET_CARTELLA],
            });
        }
        dispatch(
            setMessage({ type: 'success', text: 'Dati salvati correttamente.' })
        );
    }, [record]);

    if (query.loading || related.loading) return <Spinner />
    return <div>
        <h2>Scheda di presa in carico</h2>
        <Alert variant={'primary'}>
            <p><strong>Struttura sanitaria</strong><br />
            {_get(parent, 'strutturaSanitaria.nome', '-')}</p>
        </Alert>
        <FormViewer
            initialValues={record}
            schema={schema as Schema}
            onSubmit={submit}
            slots={{
                ButtonBar: (args) => <>
                    <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                        {record.uniqueId && <>
                        <Button
                            variant="danger"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm("Vuoi continuare?")) {
                                    remove();
                                }
                            }}
                        >
                            Elimina scheda di presa in carico
                        </Button>
                        </>}
                        <Button variant="primary" type='submit' className='ms-auto'>
                            Salva
                        </Button>
                    </div>
                </>
            }}
        />
    </div>;
}

export default EditSchedaPresaInCarico;
