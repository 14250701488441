import _ from 'lodash';
import React, { Fragment, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormViewer } from '@ai4/form-viewer';
import { useDataRequest } from '@ai4/data-request';
import Schema from '@ai4/form-viewer/dist/types/schema';
import $schema from './change-password.schema.json';
import { setMessage } from 'src/@bootstrap/features/app';

function ChangePassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { useRestRequest } = useDataRequest();
    const [req, res] = useRestRequest({
        path: 'api/{ver}/personal/change-password',
        method: 'PUT',
        jwt: true,
    });

    const submit = useCallback(async ({values}) => {
        try {
            await req({
                data: values
            });
            dispatch(setMessage({ type: 'success', text: 'Password modificata' }));
            navigate("/");
        } catch (e) {
            dispatch(setMessage({ type: 'error', text: 'Errore durante la modifica' }));
        }
    }, []);
    
    return <FormViewer
        initialValues={{}}
        schema={$schema as Schema}
        onSubmit={submit}
    />
}

export default ChangePassword;