import _get from 'lodash/get';
import React, { Fragment, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Spinner, Accordion, Card, Tab, Tabs, Button, Modal, ListGroup, Table } from 'react-bootstrap';
import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { useDataRequest } from '@ai4/data-request';
import { FormViewer } from '@ai4/form-viewer';
import { formatDate } from 'src/@bootstrap/components/Crud/helpers';
import $rivalutazione from '../schema/rivalutazione.json';
import Rivalutazione from './Rivalutazione';
import { Helpers } from '@ai4/records-management';

const RecordContext = createContext<any>(null);

export function EditProgettoIndividuale(props: any) {
    const {
        record,
        handleClose,
        extra = {},
    } = props;
    const { cartellaUniqueId } = extra;

    const canGeneratePAI = cartellaUniqueId && !record.paiUniqueId;

    const [data, setData] = useState();
    const { useRestRequest } = useDataRequest();
    const [exec, res] = useRestRequest({
        method: 'GET',
        path: `api/{ver}/sgdt/progetti-individuali/${record.uniqueId}`,
		jwt: true,
	});
    const [execGenerate, resGenerate] = useRestRequest({
        method: 'POST',
        path: `api/{ver}/sgdt/progetti-individuali/${record.uniqueId}/pai`,
		jwt: true,
	});

    const loadDetail = useCallback(async () => {
        try {
            const res: any = await exec();
            if (res) {
                setData(res);
            }
        } catch(e) {

        }
    }, []);

    const PAIUrl = (cartellaUniqueId, paiUniqueId) => `/cartelle/dettaglio/${cartellaUniqueId}?t=${+new Date()}#${Helpers.buildHash([{ tab: 'progetti' }, { entity: 'progetti', action: 'edit', uniqueId: paiUniqueId }])}`;

    const generatePAI = useCallback(async () => {
        try {
            const res: any = await execGenerate();
            if (res) {
                const { paiUniqueId } = res;
                if (paiUniqueId) {
                    const interventoUniqueId = await execGenerate({
                        path: `api/{ver}/cartellasociosanitaria/interventi`,
                        method: 'POST',
                        data: {
                            dati: {},
                            progettoUniqueId: paiUniqueId,
                        },
                    });
                    const affidamentoUniqueId = await execGenerate({
                        path: `api/{ver}/cartellasociosanitaria/affidamenti`,
                        method: 'POST',
                        data: {
                            dati: {},
                            interventoUniqueId,
                        },
                    });
                    handleClose();
                    window.location.href = PAIUrl(cartellaUniqueId, paiUniqueId);
                }
            }
        } catch(e) {

        }
    }, [cartellaUniqueId]);

    useEffect(() => {
        loadDetail();
    }, []);

    const { organization, performers } = _get<any[]>(data, 'activities[0].performers', []).reduce((acc, perf) => {
        if (perf.type === 'Organization') return { ...acc, organization: perf };
        return { ...acc, performers: [...acc.performers, perf] };
    }, { performers: [], })

    return (
        <Edit>
            {res.loading && <Spinner />}
            {!res.loading && <Fragment>
                <TitleBar
                    path={[
                        `# ${_get(data, 'numeroPratica')}`,
                    ]}
                    onBack={handleClose}
                    frontendLink={canGeneratePAI ? <div className='ms-auto'><Button variant='success' onClick={generatePAI}>Genera PAI</Button></div> : undefined}
                />
                <RecordContext.Provider value={data}>
                    <Container>
                        <Row>
                            <Col sm={8}>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Row>
                                            <Cell field='identifier' label='Identificativo' />
                                            <Cell field='numeroPratica' label='Numero Pratica' />
                                            <Cell field='importLastUpdated' label='Data ultimo aggiornamento' type='datetime' block />
                                            <Cell field='versione' label='Versione PI' />
                                            <Cell field='author.display' label='Emesso da' block />
                                        </Row>
                                        <Row>
                                            <Cell field='patient.display' label='Paziente' block />
                                        </Row>
                                        <Row>
                                            <Cell field='activities[0].codings[0]' label='Regime di cura' render={(value, data) => `${_get(value, 'code')} ${_get(value, 'display')}`} block />
                                            <Cell field='activities[0].reasonCodes[0]' label='Profilo di cura'  render={(value, data) => `${_get(value, 'code')} ${_get(value, 'display')}`} block />
                                            <Cell field='activities[0].occurrencePeriodStart' label='Data inizio pratica' type='date' />
                                            <Cell field='activities[0].occurrencePeriodEnd' label='Data fine pratica' type='date' />
                                        </Row>
                                        <Row>
                                            <Cell field='periodStart' label='Data inizio assistenza' type='date' />
                                        </Row>
                                        <Row>
                                            <Cell field='activities[0].requisitionValue' label='Numero impegnativa' />
                                            <Cell field='activities[0].requisitionPeriodStart' label='Data impegnativa' type='date' />
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Card.Title>
                                            {_get(organization, 'display')}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <Row>
                                                <Cell field='activities[0].quantity' label='Numero totale accessi previsti' />
                                            </Row>
                                        </Card.Subtitle>

                                        {performers.length > 0 && <Fragment>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Tipologia figura professionale</th>
                                                    <th>Professionista</th>
                                                    <th>Numero accessi previsti</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {performers.map((item, k) => (
                                                <tr key={k}>
                                                    <td>{_get(item, 'display')}</td>
                                                    <td>{_get(item, 'codings[0].display')}</td>
                                                    <td>{_get(item, 'numeroAccessi')}</td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        </Fragment>}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Rivalutazione record={record} />
                            </Col>
                        </Row>
                        <Accordion>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>Condizioni cliniche</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                    {_get(data, 'addresses', []).map((item, k) => (
                                        <Cell key={`addr_${k}`} data={item} field='codings[0].display' label={_get(item, 'category[0].display')} block />
                                    ))}
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {_get(data, 'supportingInfos', []).map((item, k) => (
                            <Accordion.Item eventKey={`supportingInfos_${k}`}>
                                <Accordion.Header>Valutazione {_get(item, 'coding[0].display')}</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Cell key={k} data={item} field='coding[0].display' label='Descrizione' block />
                                        <Cell key={k} data={item} field='value' label='Punteggio finale' block />
                                        <Cell key={k} data={item} field='questionnaireResponses[0].authored' label='Data' type='datetime' block />
                                        <Cell key={k} data={item} field='questionnaireResponses[0]' label='Tipologia di valutazione' render={(value, data) => `${_get(value, 'tipologiaValutazioneCode')} - ${_get(value, 'tipologiaValutazioneDisplay')}`} block />
                                        <Cell key={k} data={item} field='questionnaireResponses[0].partecipantiValutazioneDisplay' label='Partecipanti alla valutazione' block />
                                        <Cell key={k} data={item} field='questionnaireResponses[0].luogoValutazioneDisplay' label='Luogo di valutazione' block />
                                    </Row>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Domanda</th>
                                                <th>Risposta</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_get(item, 'questionnaireResponses[0].items', []).map((resp, kk) => (
                                            <tr key={`resp_${kk}`}>
                                                <td>{_get(resp, 'text')}</td>
                                                <td>{renderVal(_get(resp, 'answers[0].valueString'))}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            ))}
                        </Accordion>
                    </Container>
                </RecordContext.Provider>
            </Fragment>}
        </Edit>
    );
}

function renderVal(str: string) {
    if (str === 'true') return 'Sì';
    if (str === 'false') return 'No';
    return str;
}

interface CellProps {
    data?: any;
    label?: string;
    field: any;
    type?: 'text' | 'datetime' | 'date';
    render?: (value: any, data: any) => string;
    block?: boolean;
    err?: boolean; // @toremove
}

function Cell(props: CellProps) {
    const { data, label, field, type = 'text', render, block, err } = props;
    const defaultData = useContext(RecordContext);
    const defaultLabel = camelCaseToWords(field);
    let value = _get(data || defaultData, field);
    if (value && type === 'datetime') {
        value = formatDate(value, 'full');
    }
    if (value && type === 'date') {
        value = formatDate(value, 'date');
    }
    if (render) {
        value = render(value, data);
    }
    if (value && typeof value !== 'string') {
        value = JSON.stringify(value);
    }

    return <Col sm={block ? 12 : 6} className={`mb-1 ${err ? 'text-danger' : ''}`}>
        <div className="ViewCell">
            <div><strong>{label || defaultLabel}</strong></div>
            <div>{value || '-'}</div>
        </div>
    </Col>;
}

export function camelCaseToWords(s: string) {
    const pts = s.split('.');
    const result = pts[pts.length-1].replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}  

export default EditProgettoIndividuale;
