import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Spinner } from 'react-bootstrap';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { FormViewer } from '@ai4/form-viewer';
import { gql, useDataRequest } from '@ai4/data-request';
import { setMessage } from 'src/@bootstrap/features/app';
import $schema from './schema/config.json';

const RELATED = gql`
    query getRelatedSGDTConfig {
		struttureServiziQuery {
            erogatori {
                list {
                    uniqueId
                    nome
                }
            }
		}
	}
`;

const Config = () => {
    const [erogatore, setErogatore] = useState<any>();
    const dispatch = useDispatch();
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
	const [exec] = useRestRequest({
        path: '@later',
        method: 'GET',
        jwt: true,
    });

    const erogatori = _get(related.data, "struttureServiziQuery.erogatori.list", []);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});

    useEffect(() => {
        if (!related.loading && erogatori.length > 0) {
            setErogatore(erogatori[0]);
        }
    }, [related.loading]);

    useEffect(() => {
        if (erogatore) {
            setLoading(true);
            exec({
                path: `api/{ver}/sgdt/settings/erogatori/${erogatore.uniqueId}`
            }).then((res: any) => {
                if (res.result && res.settings) {
                    setValues(res.settings);
                }
                setLoading(false);
            });
        }
    }, [erogatore]);

    const submit = useCallback(async (values) => {
        try {
            const res = await exec({
                path: `api/{ver}/sgdt/settings/erogatori/${erogatore.uniqueId}`,
                method: 'POST',
                data: values,
            });
            dispatch(setMessage({ type: 'success', text: 'Configurazione modificata' }));
            return true;
        }
        catch (e) {
            dispatch(setMessage({ type: 'error', text: 'Errore durante la modifica' }));
            return false;
        }
    }, [erogatore]);

    if (loading || related.loading) return <Spinner />
    return <Fragment>
        <div className="hstack gap-3">
            <h2>Configurazione SGDT</h2>
            
            <Dropdown>
                <Dropdown.Toggle variant="primary">{erogatore ? erogatore.nome : ' - '}</Dropdown.Toggle>
                <Dropdown.Menu>
                {erogatori.map((erogatore, index) => (
                    <Dropdown.Item onClick={() => setErogatore(erogatore)}>{erogatore.nome}</Dropdown.Item>
                ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>

        <div className="row">
            <div className="col-sm-6">
                <FormViewer
                    initialValues={values}
                    schema={$schema as Schema}
                    onSubmit={async (args) => {
                        const { values, form } = args;
                        await submit(values);
                        form.setSubmitting(false);
                    }}
                />
            </div>
        </div>
    </Fragment>
}


export default Config;