import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useEffect } from 'react';
import RecordsManagement from '@ai4/records-management';
import { gql, useDataRequest } from "@ai4/data-request";
import { dateFormatter } from 'src/app/helpers/formatters';
import Import from './components/Import';
import EditProgettoIndividuale from './edit/ProgettoIndividuale';
import { formatDate } from 'src/@bootstrap/components/Crud/helpers';
import { UTCToTZ } from 'src/@bootstrap/helpers';

interface Props {
    filters: any;
}

const List = (props: Props) => {
    const { filters } = props;
    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/sgdt/progetti-individuali/import/detail',
        method: 'GET',
        jwt: true,
    });
    useEffect(() => {
        load();
    }, [filters]);

    const load = useCallback(() => {
        reqList({
            queryString: filters,
        });
    }, [filters]);

	const rows = _get(resList, 'data.pianiIndividuali', []);
    
    const args = {
        module,
		entity: 'sgdt-progetti-individuali',
		columns: [
            {
                dataField: "cognome",
                text: "Cognome",
            },
            {
                dataField: "nome",
                text: "Nome",
            },
            {
                dataField: "codiceFiscale",
                text: "CF",
            },
            {
                dataField: "dataNascita",
                text: "Data di nascita",
                formatter: dateFormatter
            },
            {
                dataField: "codicePI",
                text: "Codice PI",
            },
            {
                dataField: "versione",
                text: "Versione",
            },
		],
		rows,
		dataLoading: resList.loading,
		formSchema: {},
		submitResponse: null,
		// hideEditColumns: true,
        clickRowToSelect: true,
		slots: {
			header: ({ onCreate }) => null,
            edit: EditProgettoIndividuale,
		},
        checkFeature: (feature, row) => {
            return feature === 'edit';
        },
		onBoot: () => {},
		onInit: () => {},
		onChange: () => {},
		onSubmit: () => {},
		onCancel: () => {},
		// onSelectedRow: () => {},
		onDeleteRow: () => {},
		onAddExtraData: () => {},
    }

    return <Fragment>
        {filters && <Fragment>
            <Import {...filters} onImport={load} />
            {rows && rows.length > 0 && <Fragment>
                <h2>PI importati durante l'ultima importazione</h2>
                {/* @ts-ignore */}
                <RecordsManagement {...args} />
            </Fragment>}
        </Fragment>}
    </Fragment>
}

export default List;