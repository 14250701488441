import { formatDate } from "src/@bootstrap/components/Crud/helpers";

export const dateFormatter = (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
	if (!cell) return;
	return formatDate(cell, 'date');
};

export const timeFormatter = (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
	if (!cell) return;
	return formatDate(cell, 'time');
};

export const boolFormatter = (cell: any, row: any, rowIndex: number, formatExtraData: any) => {
	if (cell === true) return '✅';
	return '';
};